.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: center;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background-color: #fffff4;
}

.word-vis {
  margin: 10px;
}

.grid {
  margin: 10px;
}
