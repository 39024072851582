.container {
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 30px auto auto auto;
  display: flex;
}

.button {
  background-color: transparent;
  height: 3em;
  width: 6em;
  border-radius: 6px;
  margin: 0 10px;
  cursor: pointer;
}
